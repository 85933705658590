<template>
  <div
    id="contents"
    class="plc_monitor plc_metal_status"
    :class="{
      counter_status: tabIndex == 0,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="asideIndex"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(aside, index) in asideIndex"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a @click="SetOpenTabIndex(index)">{{ aside.title }}</a>
          </li>
        </ul>
      </div>
      <CounterRealtimeForm v-if="tabIndex == 0" />
      <GranulatorForm v-if="tabIndex == 1" />
      <EmergencyModal v-if="emergencyOpen" @onclose="emergencyOpen = false" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/19/CounterRealtimeForm';
import GranulatorForm from '@/views/forms/Custom/Monitor/19/GranulatorForm';
import EmergencyModal from '@/layouts/components/EmergencyModal';

// import DefaultPlcForm from '@/views/forms/Custom/Monitor/19/DefaultPlcForm';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    CounterRealtimeForm,
    EmergencyModal,
    GranulatorForm,
    // DefaultPlcForm,
  },
  data() {
    return {
      emergencyOpen: false,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      asideIndex: [
        { title: '실시간 사출기현황', name: 'counter_time_status' },
        { title: '실시간 조립기현황', name: 'granulator_status' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
      lots: 'getLotFromTodayWorkOrder',
    }),
  },

  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),

    destroyed() {
      console.log('destroyed');
      clearInterval(this.interval);
      clearInterval(this.timeInterval);
      clearInterval(this.currentTimeInterval);
    },
    async fetch_lot_all() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_LOT_ALL')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '작업지시서를 불러오는 중 오류발생.',
          );
        });
      this.hideSpinner();
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }

    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER_NO_POP_UP(
      'FETCH_LOT_TODAY_FROM_WORKORDER',
      '긴급작업지시서',
    );

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      await this.FETCH_NO_SPINNER_NO_POP_UP(
        'FETCH_LOT_TODAY_FROM_WORKORDER',
        '긴급작업지시서',
      );

      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    if (
      this.lots.length > 0 &&
      this.lots[0].emergency_yn &&
      this.lots[0].lot_type_id == 1
    ) {
      if (localStorage.getItem('emergency_id') != this.lots[0].id) {
        this.emergencyOpen = true;
      }
    } else {
      this.emergencyOpen = false;
    }
  },
};
</script>

<style lang="scss">
@import 'taegwang_monitoring';
</style>
