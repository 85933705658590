<template>
  <div
    v-show="cur_date === lot.input_date"
    class="modalPopup taegwang_emergency_workorder_modal"
    v-if="modalClose"
  >
    <div class="modal_header">
      <h2 class="title">[긴급]</h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
    </div>

    <div class="modal_body">
      <h3>
        [긴급]
        <p>작업지시</p>
      </h3>
      <ul>
        <li>
          <span>품명 : </span
          >{{
            lot.product_id == undefined
              ? findInfoFromId(products, lot.product_id).name
              : findInfoFromId(products, lot.product_id).name
          }}
        </li>
        <li>
          <span>수량 : </span>{{ `${$makeComma(lot.quantity)}` }}
          {{
            `${
              findInfoFromId(
                units,
                findInfoFromId(products, lot.product_id).stock_unit_id,
              ).name
            }`
          }}
        </li>
        <li><span>담당 : </span>사출팀</li>
        <li><span>납기 : </span>{{ yyyy년MM월dd일(lot.deadline_date) }}</li>
      </ul>
    </div>
    <div class="modal_footer">
      <button class="btn_sub2" @click="isModalClose">
        확인
      </button>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { yyyymmdd } from '@/utils/func';
import { mapGetters } from 'vuex';

export default {
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  data() {
    return {
      modalClose: true,
      cur_date: yyyymmdd(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      lots: 'getLot',
      units: 'getUnitCodes',
    }),
    lot() {
      let lot = this.lodash
        .clonedeep(this.lots)
        .sort((a, b) => b.input_date - a.input_date)
        .find(x => x.emergency_yn == true && x.lot_type_id == 1);
      if (this.lots.length < 1) {
        return [];
      } else {
        return lot;
      }
    },
  },
  methods: {
    isModalClose() {
      localStorage.setItem('emergency_id', this.lot.id);
      this.$emit('onclose');
    },
    yyyy년MM월dd일(date2) {
      const date = new Date(date2);
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();

      return [
        date.getFullYear(),
        '년 ' + (MM > 9 ? MM : '0' + MM),
        '월 ' + (dd > 9 ? dd : '0' + dd),
        '일 ',
      ].join('');
    },
    async fetch_lot_all() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_LOT_ALL')
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '불러오기 오류',
            '작업지시서를 불러오는 중 오류발생.',
          );
        });
      this.hideSpinner();
    },
  },
  async created() {
    if (this.lots.length < 1) {
      await this.fetch_lot_all();
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
  },
};
</script>

<style lang="scss" scoped></style>
