<template>
  <div class="article" :class="{ active: fullScreen == true }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>실시간 조립기현황</h2>
          <span
            >조회시간 :
            {{
              getCounterSensor.length > 0
                ? getCounterSensor[0].create_time.substr(0, 19)
                : ''
            }}</span
          >
        </div>

        <div
          class="monitor_body"
          v-for="(item, index) in getCounterSensor"
          :key="index"
        >
          <div class="monitor_card good_card">
            <h3>인터페이스</h3>
            <p class="interface">
              {{ item.plc_sensor_name.split('-')[0] }}
            </p>
          </div>
          <div class="monitor_card good_card">
            <h3>생산수량</h3>
            <p>{{ $makeComma($makeNumber(item.value[0])) }}</p>
          </div>
          <div class="monitor_card bad_card">
            <h3>장비불량횟수</h3>
            <p>{{ $makeComma($makeNumber(item.value[1])) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      counter_interval: null,
      timer: 1,
      fullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    getCounterSensor() {
      const filterSensors = this.plc_sensor_logs
        .filter(x => x.plc_sensor_name.includes('조립기'))
        .map(x => ({
          ...x,
          name: x.plc_sensor_name.split(' ')[1],
        }));

      const obj = filterSensors.reduce(function(rv, x) {
        (rv[x['name']] = rv[x['name']] || []).push(x);
        return rv;
      }, {});
      console.log('obj', obj);
      let arr = [];
      for (let k in obj) {
        if (k != 'undefined' && k != 'null') {
          arr.push({
            plc_sensor_name: obj[k][0].plc_sensor_name,
            value: obj[k].map(x => x.value),
            create_time: obj[k][0].create_time,
            plc_sensor_id: obj[k][0].plc_sensor_id,
          });
        }
      }
      arr = arr.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
      return arr;
    },
  },
  watch: {
    async timer(newValue) {
      if (newValue == 0 && this.tabIndex == 1) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '조립기현황');
        this.timer = 10;
      }
    },
  },
  destroyed() {
    clearInterval(this.counter_interval);
  },
  async created() {
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '조립기현황');

    clearInterval(this.counter_interval);
    this.counter_interval = setInterval(() => {
      this.timer -= 1;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
